<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16"
  >
    <v-row class="mb-0 d-flex justify-center align-center">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="pb-0 mb-0"
      >
        <v-card class="white px-auto pb-0 mb-0">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-home-circle"
              >
                HOME INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="3">
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step step="4">
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="mt-0 pt-1"
      >
        <v-card
          class="mt-0 mb-16"
        >
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-form
              ref="form"
              class="px-8 font-weight-bold"
              @submit.prevent="submit"
            >
              <v-row>
                <v-col>
                  <div class="title-text text-center mt-2">
                    Let's Get Some Details
                  </div>
                </v-col>
              </v-row>
              <!------------------------Select: Details ----------------------------->
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Zip Code"
                    rules="required|numeric"
                  >
                    <v-autocomplete
                      v-model="zipCode"
                      :items="allzips"
                      :error-messages="errors"
                      :autocomplete="Date.now()"
                      counter="5"
                      label="Zip code"
                      color="primary"
                      outlined
                      @change="getpostcodedata"
                      @click="clickedFieldGlobal('zipCode', 1, 'Homeowners Survey v1')"
                    ></v-autocomplete>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Property Type"
                    rules="required"
                  >
                    <v-select
                      v-model="propertyType"
                      :items="propertyTypes"
                      :error-messages="errors"
                      outlined
                      label="Property Type"
                      @click="clickedFieldGlobal('propertyType', 2, 'Homeowners Survey v1')"
                    >
                    </v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Property Use"
                    rules="required"
                  >
                    <v-select
                      v-model="propertyUse"
                      :items="dwellUses"
                      item-text="text"
                      item-value="code"
                      :error-messages="errors"
                      outlined
                      label="How will the property be used?"
                      @change="logUse"
                      @click="clickedFieldGlobal('propertyUse', 3, 'Homeowners Survey v1')"
                    >
                    </v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <!----------------------- Property Address ----------------------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Property Address
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <vuetify-google-autocomplete
                    id="map1"
                    ref="address1"
                    classname="form-control"
                    outlined
                    placeholder="Please type your address"
                    country="us"
                    @placechanged="getAddressData"
                  >
                  </vuetify-google-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address"
                    rules="required"
                  >
                    <v-text-field
                      v-model="addr1"
                      :error-messages="errors"
                      label="Address"
                      @click="clickedFieldGlobal('address', 4, 'Homeowners Survey v1')"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="City"
                    rules="required"
                  >
                    <v-text-field
                      v-model="city"
                      :error-messages="errors"
                      label="City"
                      @click="clickedFieldGlobal('city', 5, 'Homeowners Survey v1')"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="State"
                    rules="required"
                  >
                    <v-text-field
                      v-model="state"
                      :error-messages="errors"
                      label="State"
                      @click="clickedFieldGlobal('state', 6, 'Homeowners Survey v1')"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Zip Code"
                    rules="required|numeric"
                  >
                    <v-text-field
                      v-model="zip"
                      :error-messages="errors"
                      label="Zip Code"
                      @click="clickedFieldGlobal('zip', 7, 'Homeowners Survey v1')"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ul style="list-style: none;">
                    <li>
                      <v-checkbox
                        v-model="timeAtAddress"
                        label="I have lived at the above address for less than 24 months"
                        hide-details
                        @click="clickedField(`less than 24 months: ${timeAtAddress}`, 8)"
                      ></v-checkbox>
                    </li>
                    <li>
                      <v-checkbox
                        v-model="differentMailing"
                        label="I have a different Mailing Address"
                        hide-details
                        @click="clickedField(`different Mailing Address: ${differentMailing}`, 9)"
                      ></v-checkbox>
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <!-------------------PRIOR ADDRESS----------------------->
              <div v-if="timeAtAddress">
                <v-row>
                  <v-col>
                    <div class="sub-title mt-6">
                      Prior Address
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <vuetify-google-autocomplete
                      id="map2"
                      ref="address2"
                      classname="form-control"
                      outlined
                      placeholder="Please type your address"
                      country="us"
                      @placechanged="getPriorAddressData"
                    >
                    </vuetify-google-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <v-text-field
                        v-model="priorAddress"
                        disabled
                        :error-messages="errors"
                        label="Address"
                        @click="clickedFieldGlobal('priorAddress', 10, 'Homeowners Survey v1')"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <v-text-field
                        v-model="priorCity"
                        disabled
                        :error-messages="errors"
                        label="City"
                        @click="clickedFieldGlobal('priorCity', 11, 'Homeowners Survey v1')"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="State"
                      rules="required"
                    >
                      <v-text-field
                        v-model="priorState"
                        :error-messages="errors"
                        disabled
                        label="State"
                        @click="clickedFieldGlobal('priorState', 12, 'Homeowners Survey v1')"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Zip Code"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="priorZip"
                        :error-messages="errors"
                        disabled
                        label="Zip Code"
                        @click="clickedFieldGlobal('priorZip', 13, 'Homeowners Survey v1')"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <!-------------------MAILING ADDRESS----------------------->
              <div v-if="differentMailing">
                <v-row>
                  <v-col>
                    <div class="sub-title mt-6">
                      Mailing Address
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <vuetify-google-autocomplete
                      id="map3"
                      ref="address3"
                      classname="form-control"
                      outlined
                      placeholder="Please type your address"
                      country="us"
                      @placechanged="getMailingAddressData"
                    >
                    </vuetify-google-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <v-text-field
                        v-model="mailingAddress"
                        disabled
                        :error-messages="errors"
                        label="Address"
                        @click="clickedFieldGlobal('mailingAddress', 14, 'Homeowners Survey v1')"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <v-text-field
                        v-model="mailingCity"
                        disabled
                        :error-messages="errors"
                        label="City"
                        @click="clickedFieldGlobal('mailingCity', 15, 'Homeowners Survey v1')"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="State"
                      rules="required"
                    >
                      <v-text-field
                        v-model="mailingState"
                        :error-messages="errors"
                        disabled
                        label="State"
                        @click="clickedFieldGlobal('mailingState', 16, 'Homeowners Survey v1')"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Zip Code"
                      rules="required|numeric"
                    >
                      <v-text-field
                        v-model="mailingZip"
                        :error-messages="errors"
                        disabled
                        label="Zip Code"
                        @click="clickedFieldGlobal('mailingZip', 17, 'Homeowners Survey v1')"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
              <!-------------------------------------Personal Details---------------------------->
              <v-row>
                <v-col>
                  <div class="sub-title mt-2">
                    Personal Details
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <validation-provider
                    v-slot="{ errors }"
                    name="First Name"
                    rules="alpha|required"
                  >
                    <v-text-field
                      v-model="firstName"
                      label="First Name"
                      outlined
                      :error-messages="errors"
                      @click="clickedFieldGlobal('firstName', 18, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Last Name"
                    :rules="{ required: true, regex: /^[a-zA-Z\-]+$/ }"
                  >
                    <v-text-field
                      v-model="lastName"
                      outlined
                      label="Last Name"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('lastName', 19, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row><v-col>Date of Birth</v-col></v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Birth Day"
                    rules="required"
                  >
                    <v-select
                      v-model="bdays"
                      :items="daysavailable"
                      label="Day"
                      outlined
                      color="primary"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('bdays', 20, 'Homeowners Survey v1')"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Birth Month"
                    rules="required"
                  >
                    <v-select
                      v-model="bmonths"
                      :items="monthsavailable"
                      item-text="state"
                      item-value="abbr"
                      label="Month"
                      outlined
                      color="primary"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('bmonths', 21, 'Homeowners Survey v1')"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Birth Year"
                    rules="required"
                  >
                    <v-select
                      v-model="byears"
                      :items="yearsavailable"
                      label="Year"
                      outlined
                      color="primary"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('byears', 22, 'Homeowners Survey v1')"
                    ></v-select>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="8"
                  md="8"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Email"
                    rules="required|email"
                  >
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      :error-messages="errors"
                      @click="clickedFieldGlobal('email', 23, 'Homeowners Survey v1')"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="d-flex justify-end">
                    <v-btn
                      x-large
                      :disabled="invalid"
                      color="primary"
                      class="mb-2 font-weight-bold"
                      @click="next"
                    >
                      Get Quotes
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center">
                <v-col class="d-flex justify-center">
                  <v-progress-circular
                    v-show="loading"
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
              </v-row>
              <v-dialog
                v-model="errorDialog"
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <v-card>
                  <v-toolbar
                    color="primary"
                    dark
                  >
                    We're Sorry
                  </v-toolbar>
                  <v-card-text>
                    <div class="text-h2 pa-12">
                      Insurance for this type of property use is not available at this time.
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <v-btn
                      text
                      @click="errorDialog = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import moment from 'moment'
  const zipcodes = require('zipcodes')
  export default {
    data () {
      return {
        zipCode: '95035',
        propertyType: 'Single Family Home',
        propertyUse: '',
        addr1: '840 Bolton Drive',
        city: 'Milpitas',
        state: 'CA',
        zip: '95035',
        dob: '05/23/1989',
        firstName: 'Bob',
        lastName: 'Buttons',
        email: 'bob@buttons.com',
        byears: '1989',
        bmonths: 'MAY',
        bdays: '23',
        timeAtAddress: false,
        differentMailing: '',
        priorAddress: '',
        priorCity: '',
        priorZip: '',
        priorState: '',
        mailingAddress: '',
        mailingCity: '',
        mailingZip: '',
        mailingState: '',
        errorDialog: false,
        propertyTypes: ['Single Family Home', 'Townhome', 'Rowhome', 'Duplex'],
        propertyUses: ['Full-time primary residence - no other part-time residences', 'Primary residence with part-time residence(s) elsewhere', 'Secondary Residence with primary residence elsewhere', 'Property owned by the insured and rented to a tenant with a long term lease', 'Property owned by the insured and rented with a short term lease / vacation rental', 'Vacant Residence'],
        roofTypes: ['Roof Type 1', 'Roof Type 2', 'Roof Type 3', 'Roof Type 4'],
        constructionTypes: ['Type 1', 'Type 2', 'Type 3', 'Type 4']
      }
    },
    computed: {
      loading () {
        return this.$store.getters.getloading
      },
      propLookupObj () {
        return {
          addr1: this.addr1,
          city: this.city,
          stateProvCd: this.state,
          postalCode: this.zip
        }
      },
      dwellUses () {
        return this.$store.getters.getHomeDwellUses
      },
      yearsavailable () {
        let maxyear = 2021
        let startingyear = maxyear - 70
        let years = []
        for (let i = startingyear; i < maxyear; i++) {
          years.push(i.toString())
        }
        return years.reverse()
      },
      monthsavailable () {
        let months = moment.monthsShort()
        let ucMonths = []
        months.map(entry => {
          ucMonths.push(entry.toUpperCase())
        })
        return ucMonths
      },
      daysavailable () {
        let days = []
        let lastday = 31
        for (let i = 1; i <= lastday; i++) {
          days.push(i.toString())
        }
        return days
      },
      allzips () {
        let usazips = zipcodes.radius(67052, 1500)
        console.log('codes loaded!  ', usazips[0])
        let codes = usazips.slice(0, 4)
        console.log(codes)
        return usazips
      },
      basicSelected () {
        return this.selectedFixtures === 'Basic'
      },
      extraSelected () {
        return this.selectedFixtures === 'Extra'
      },
      topSelected () {
        return this.selectedFixtures === 'Top'
      }
    },
    methods: {
      logUse () {
        console.log(this.propertyUse)
      },
      dialogError () {
        if (this.propertyUse !== 'Full-time primary residence - no other part-time residences') {
          this.errorDialog = true
        } this.errorDialog = false
      },
      getAddressData (addressData1, placeResultData, id) {
        this.addr1 = addressData1.name
        this.city = addressData1.locality
        this.zip = addressData1.postal_code
        this.state = addressData1.administrative_area_level_1
      },
      getPriorAddressData (addressData2, placeResultData, id) {
        this.priorAddress = addressData2.name
        this.priorCity = addressData2.locality
        this.priorZip = addressData2.postal_code
        this.priorState = addressData2.administrative_area_level_1
      },
      getMailingAddressData (addressData3, placeResultData, id) {
        this.mailingAddress = addressData3.name
        this.mailingCity = addressData3.locality
        this.mailingZip = addressData3.postal_code
        this.mailingState = addressData3.administrative_area_level_1
      },
      getpostcodedata (postcode) {
        console.log('looking zip code data ')
        let loc = zipcodes.lookup(postcode)
        console.log('zip code data ', loc)
        this.StateOrProvinceCode = loc.state
        this.$store.dispatch('checkpostcode', postcode)
        console.log(this.$store.getters.postcodedata)
      },
      setPlan (fixture) {
        this.selectedFixtures = fixture
      },
      async next () {
        let complete = await this.$refs.observer.validate()
        console.log(complete)
        if (!complete) {
          console.log('Error validating form')
        } else {
          Promise.all(
            [this.$store.dispatch('getInitialHomeQuote', { deductible: 1000, type: 'large', policyTypeCd: '03', dwellUseCd: this.propertyUse, addr: this.propLookupObj }),
             this.$store.dispatch('getInitialHomeQuote', { deductible: 2000, type: 'medium', policyTypeCd: '03', dwellUseCd: this.propertyUse, addr: this.propLookupObj }),
             this.$store.dispatch('getInitialHomeQuote', { deductible: 2500, type: 'small', policyTypeCd: '03', dwellUseCd: this.propertyUse, addr: this.propLookupObj }),
             this.$store.dispatch('getInitialHomeQuote', { deductible: 1000, type: 'large', policyTypeCd: '05', dwellUseCd: this.propertyUse, addr: this.propLookupObj }),
             this.$store.dispatch('getInitialHomeQuote', { deductible: 2000, type: 'medium', policyTypeCd: '05', dwellUseCd: this.propertyUse, addr: this.propLookupObj }),
             this.$store.dispatch('getInitialHomeQuote', { deductible: 2500, type: 'small', policyTypeCd: '05', dwellUseCd: this.propertyUse, addr: this.propLookupObj })]
          ).then(() => {
            this.$router.push({ path: '/homeowners/selectplan' })
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.green-background {
  background-color: #D8FDD7;
}
.title-text {
  font-weight: normal;
  font-size: 36px;
  color: #00A1B7;
}
.sub-title{
  font-size: 20px;
  font-weight: 700;
  color: #00A1B7;
}
.plan-title {
  font-size: 30px;
}
.plan-sub {
  font-size: 26px;
}
.selected {
  background-color: #005F6C;
  color: white
}
</style>
